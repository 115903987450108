jQuery.noConflict();
jQuery(document).ready(function($) {

    $(window).on('load', function () {

        // owlCarousel
        //$('.owl-carousel').owlCarousel();
        $('.slide-fotos').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 30,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 4
                }
            }

        });



        $('.slide-principal').owlCarousel({
            pagination: true,
            nav: true,
            loop: true,
            margin: 0,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });




        $(".owl-prev").html('<span class="fa fa-angle-left"></span>');
        $(".owl-next").html('<span class="fa fa-angle-right"></span>');

    });



    // jquery code
    $(document).ready(function () {

        // toggle menu
        $("body").on("click", ".menu- a", function (e) {
            $(".navbar-toggler").trigger("click");
        });


      

        // Meni
        $('.navbar-toggler').click(function() {
            $('.navbar-toggler').toggleClass('collapsed');
            //$(this).addClass('current');
            $('.full-menu').toggleClass('active');
            var $target = $(this).attr('href');
            $($target).addClass('active');
            return false;
        });

        

        // Bootstrap Auto-Hiding Navbar 
        //$(".header").autoHidingNavbar();


        // Highlight the top nav as scrolling occurs
        $(window).scroll(function() {
            if ($(document).scrollTop() > 250) {
                $('.header').addClass('shrink');
            } else {
                $('.header').removeClass('shrink');
            }
        });

        $("body").on("click",".menu-item a",function(e){
            $(".navbar-toggler").trigger("click");
        });

        // Select all links with hashes
        $('.menu-item a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });




        // Animate on scroll
        // Detect request animation frame
        var scroll = window.requestAnimationFrame ||
                    // IE Fallback
                    function(callback){ window.setTimeout(callback, 1000/60)};
        var elementsToShow = document.querySelectorAll('.show-on-scroll'); 

        function loop() {

            Array.prototype.forEach.call(elementsToShow, function(element){
            if (isElementInViewport(element)) {
                element.classList.add('is-visible');
            } else {
                element.classList.remove('is-visible');
            }
            });

            scroll(loop);
        }

        // Call the loop for the first time
        loop();

        // Helper function from: http://stackoverflow.com/a/7557433/274826
        function isElementInViewport(el) {
        // special bonus for those using jQuery
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }
        var rect = el.getBoundingClientRect();
        return (
            (rect.top <= 0
            && rect.bottom >= 0)
            ||
            (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight))
            ||
            (rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
        );
        }


        




        $('.imagen-del-proyecto-home').mousemove(function(en){
            var cposn = { top: Math.abs(en.pageY) - 10, left: en.pageX + 10 };
            $('#view-project').offset(cposn);
        });
        $('.imagen-del-proyecto-home').mouseover(function(){
            $('#view-project').css('display','block');
            $color = $(this).data('color');
            $('#view-project').css('color',$color);
        });
        $('.imagen-del-proyecto-home').mouseout(function(){
            $('#view-project').css('display','none');
        });

        


        // magnificPopup for .image-link
        $('.image-link').magnificPopup({ type: 'image' });


        $('.popup-gallery').magnificPopup({
            delegate: '.owl-item:not(.cloned) a',
            type: 'image',
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
                beforeOpen: function () {
                    // just a hack that adds mfp-anim class to markup 
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title') + '<small></small>';
                }
            }
        });


        // Contact
        $('.wpcf7-form .wpcf7-text').addClass('form-control');
        $('.wpcf7-textarea').addClass('form-control');
        $('.wpcf7-date').addClass('form-control');
        $('.wpcf7-submit').addClass('btn btn-primary');
        //$('.input-xlarge').addClass('form-control');



    
        



    });

});